<template>
  <v-container fluid>
    <v-data-table
      :items="items"
      :headers="[
        { text: 'Nr', value: 'result.order', sortable: false },
        { text: 'Mannschaft', value: 'team.name', sortable: false },
        { text: 'Turnerin', value: 'person', sortable: false },
        { text: 'E1', value: 'result.e1', sortable: false },
        { text: 'E2', value: 'result.e2', sortable: false },
        { text: 'E3', value: 'result.e3', sortable: false },
        { text: 'E4', value: 'result.e4', sortable: false },
        { text: 'D-Note', value: 'result.dscore', sortable: false },
        { text: 'Penalty', value: 'result.penalty', sortable: false },
        { text: 'Endnote', value: 'result.final', sortable: false }
      ]"
      :items-per-page="-1"
      hide-default-footer
    >
      <template #item.person="{item}">{{ item.person | person }}</template>
      <template #item.team.name="{item}">
        <template v-if="item.type === 'title'"><h2>{{ item.text }}</h2></template>
        <template v-else>{{ item.team.name }}</template>
      </template>
      <template #item.result.e1="{item}">{{ item.result.e1 | float1 }}</template>
      <template #item.result.e2="{item}">{{ item.result.e2 | float1 }}</template>
      <template #item.result.e3="{item}">{{ item.result.e3 | float1 }}</template>
      <template #item.result.e4="{item}">{{ item.result.e4 | float1 }}</template>
      <template #item.result.dscore="{item}">{{ item.result.dscore | float1 }}</template>
      <template #item.result.penalty="{item}">{{ item.result.penalty | float1 }}</template>
      <template #item.result.final="{item}">{{ item.result.final }}</template>
    </v-data-table>
    <!--base-material-dialog
      v-else
      v-model="result.open"
      icon="far fa-poll-h"
      :title="`Wertung eingeben: ${dname}`"
      :sub-title="`${pname} (${tname})`"
      color="primary"
      @esc="close"
    >
      <v-radio-group
        v-if="istSprung(did)"
        v-model="result.id"
        row
      >
        <v-radio label="Sprung 1" :value="result.subpart1" />
        <v-radio label="Sprung 2" :value="result.subpart2" />
      </v-radio-group>

      <ergebnis :id="result.id" :did="did" :e="e" @del="del" @save="save" @close="close" />
    </base-material-dialog-->
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import { useCalc } from '../../../plugins/calc'
import gql from 'graphql-tag'

export default {
  name: 'geraet',

  components: {
    // Ergebnis: () => import('./ergebnis')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    did: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    result: {
      open: false,
      id: null,
      data: null,
      main: null,
      subpart1: null,
      subpart2: null,
      sprung: 1
    },
    windowHeight: window.innerHeight,
    documentHeight: -1,
    resizeObserver: null
  }),

  computed: {
    d () {
      return this.df?.find(d => d._id === this.did) || {}
    },
    height () {
      if (this.f) return this.windowHeight - 210
      const tmp = Math.max(this.windowHeight, this.documentHeight)
      return tmp < 850 ? 500 : tmp - 350
    },
    items () {
      return this.mannschaftenangeraet(this.e, this.df, this.did).map(t => {
        return [
          { type: 'title', text: `${t.team.name} (${this.$options.filters.float2_0(this.mannschaftgeraetergebnis(this.e, this.r, t.team._id, this.did))})`, result: {} },
          ...this.mannschaftturnerangeraet(this.e, this.r, t.team._id, this.did).map(a => ({
            type: 'turner',
            person: a.person,
            team: t.team,
            result: a.result,
            subparts: a.subparts
          }))
        ]
      }).reduce((acc, curr) => {
        if (this.did === 'fc78fdd9-bb80-4be4-8fb3-498a150c92b6') {
          for (const c of curr) {
            if (c.type === 'title') acc.push(c)
            else {
              acc.push({
                ...c,
                result: {
                  ...c.subparts.find(s => s.subpart === 1),
                  order: `${c.result.order}-1`
                }
              })
              acc.push({
                ...c,
                result: {
                  ...c.subparts.find(s => s.subpart === 2),
                  order: `${c.result.order}-2`
                }
              })
            }
          }
        } else {
          acc.push(...curr)
        }
        return acc
      }, [])
    },
    inline () {
      return !!['lg', 'xl'].find(b => b === this.$vuetify.breakpoint.name)
    },
    dname () {
      return this.geraet(this.df, this.result.data?._discipline)?.name
    },
    pname () {
      return this.$options.filters.person(this.result.data?.person)
    },
    tname () {
      return this.result.data?.team?.name
    },
    ori () {
      return this.r?.find(r => r._id === this.result.data?._id) || null
    },
    main () {
      return this.r?.find(r => r._id === this.result.main) || null
    },
    subparts () {
      return this.r?.filter(r => r._discipline === this.did && r.subpart !== null && r._person === this.result.data?._person)
    }
  },

  methods: {
    open (result) {
      this.result.data = result
      this.result.main = result?._id || null
      this.result.subpart1 = this.getsubpart(1)?._id || null
      this.result.subpart2 = this.getsubpart(2)?._id || null
      this.result.id = this.result.subpart1 || this.result.subpart2 || this.result.main
      this.result.open = !!result
      this.result.sprung = 1
    },
    onResize () {
      this.windowHeight = window.innerHeight
      this.documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - 50
    },
    close () {
      this.open(null)
    },
    async save (data, nr, nonext) {
      if (nr === undefined) {
        if (this.istSprung(this.did)) nr = this.result.id === this.result.subpart1 ? 1 : 2
        else nr = 0
      }

      const ori = nr === 0 ? this.ori : this.getsubpart(nr)

      if (!data) return

      const neu = {}

      if (ori.dscore !== data.dscore) neu.dscore = data.dscore
      if (ori.escore !== data.escore) neu.escore = data.escore
      if (ori.e1 !== data.e1) neu.e1 = data.e1
      if (ori.e2 !== data.e2) neu.e2 = data.e2
      if (ori.e3 !== data.e3) neu.e3 = data.e3
      if (ori.e4 !== data.e4) neu.e4 = data.e4
      if (ori.penalty !== data.penalty) neu.penalty = data.penalty
      if (ori.deduction !== data.deduction) neu.deduction = data.deduction
      if (ori.final !== data.final) neu.final = data.final

      if (Object.keys(neu).length === 0 && this.istSprung(this.did) && nr !== 0) return nonext ? null : this.next(nr)

      neu.id = data._id

      await this.mutate({
        mutation: gql`mutation($id: UUID!, $dscore: Float, $escore: Float, $e1: Float, $e2: Float, $e3: Float, $e4: Float, $penalty: Float, $deduction: Float, $final: Float) {
            StbW2023WkTeamAthleteResultUpdate(id: $id, dscore: $dscore, escore: $escore, e1: $e1, e2: $e2, e3: $e3, e4: $e4, penalty: $penalty, deduction: $deduction, final: $final) { _id }
          }`,
        variables: neu
      })

      if (this.istSprung(this.did) && nr !== 0) {
        const p1 = nr === 1 ? data : this.getsubpart(1)
        const p2 = nr === 2 ? data : this.getsubpart(2)
        const f1 = p1?.final
        const f2 = p2?.final
        const f = this.main.final

        console.log(f, f1, f2)

        if (f1 !== undefined && f2 !== undefined) {
          const tmp = JSON.parse(JSON.stringify(f1 > f2 ? p1 : p2))
          tmp._id = this.main._id

          if (f !== tmp.final) {
            await this.save(tmp, 0)
          }
        }
        return nonext ? null : this.next(nr)
      }

      if (!this.istSprung(this.did)) return nonext ? null : this.next()
    },
    async del () {
      if (await this.$root.$children[0].$refs.confirm.open('Wertung löschen?', 'Die Wertung kann nicht wiederhergestellt werden!')) {
        this.save({
          _id: this.result.id,
          dscore: null,
          escore: null,
          e1: null,
          e2: null,
          e3: null,
          e4: null,
          penalty: null,
          deduction: null,
          final: null
        }, undefined, true)
      }
    },
    next (nr) {
      if (this.istSprung(this.did)) {
        if (nr === 1) {
          this.result.id = this.result.subpart2
          return
        }
      }

      const a = this.alleturnerangeraet(this.e, this.r, this.did)
      const i = a.findIndex(t => t.result._id === this.result.main)
      if (!a?.[i + 1]?.result) {
        this.close()
      } else {
        this.open(a[i + 1].result)
      }
    },
    getsubpart (nr) {
      return this.subparts?.find(s => s.subpart === nr)
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.resizeObserver = new ResizeObserver(this.onResize)
      this.resizeObserver.observe(document.body)
      this.onResize()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    this.resizeObserver.disconnect()
  }
}
</script>

<style scoped>

</style>
