var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"items":_vm.items,"headers":[
      { text: 'Nr', value: 'result.order', sortable: false },
      { text: 'Mannschaft', value: 'team.name', sortable: false },
      { text: 'Turnerin', value: 'person', sortable: false },
      { text: 'E1', value: 'result.e1', sortable: false },
      { text: 'E2', value: 'result.e2', sortable: false },
      { text: 'E3', value: 'result.e3', sortable: false },
      { text: 'E4', value: 'result.e4', sortable: false },
      { text: 'D-Note', value: 'result.dscore', sortable: false },
      { text: 'Penalty', value: 'result.penalty', sortable: false },
      { text: 'Endnote', value: 'result.final', sortable: false }
    ],"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.person",fn:function({item}){return [_vm._v(_vm._s(_vm._f("person")(item.person)))]}},{key:"item.team.name",fn:function({item}){return [(item.type === 'title')?[_c('h2',[_vm._v(_vm._s(item.text))])]:[_vm._v(_vm._s(item.team.name))]]}},{key:"item.result.e1",fn:function({item}){return [_vm._v(_vm._s(_vm._f("float1")(item.result.e1)))]}},{key:"item.result.e2",fn:function({item}){return [_vm._v(_vm._s(_vm._f("float1")(item.result.e2)))]}},{key:"item.result.e3",fn:function({item}){return [_vm._v(_vm._s(_vm._f("float1")(item.result.e3)))]}},{key:"item.result.e4",fn:function({item}){return [_vm._v(_vm._s(_vm._f("float1")(item.result.e4)))]}},{key:"item.result.dscore",fn:function({item}){return [_vm._v(_vm._s(_vm._f("float1")(item.result.dscore)))]}},{key:"item.result.penalty",fn:function({item}){return [_vm._v(_vm._s(_vm._f("float1")(item.result.penalty)))]}},{key:"item.result.final",fn:function({item}){return [_vm._v(_vm._s(item.result.final))]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }